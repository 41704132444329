<template>
  <layout-base-center>
    <div :class="$style.login">
      <h1 :class="$style.title">
        Авторизация
      </h1>
      <div>
        <div>
          <input
            v-model="username"
            :class="$style.input"
            placeholder="Имя пользователя"
          >
        </div>
        <div :class="$style.passwordContainer">
          <input
            v-if="showPassword"
            v-model="password"
            :class="$style.input"
            placeholder="Пароль"
          >
          <input
            v-if="!showPassword"
            v-model="password"
            type="password"
            :class="$style.input"
            placeholder="Пароль"
          >
          <img
            v-if="!showPassword"
            title="Показать пароль"
            src="@/assets/images/icons/navigation/hide.svg"
            :class="$style.eyeImg"
            @click="showPassword = true"
          >
          <img
            v-if="showPassword"
            title="Скрыть пароль"
            src="@/assets/images/icons/navigation/show.svg"
            :class="$style.eyeImg"
            @click="showPassword = false"
          >
        </div>
        <div :class="$style.buttonContainer">
          <button :class="$style.button" @click="login(false)">
            Войти
          </button>
        </div>
      </div>
    </div>
    <telegram-auth
      id="tgAuth"
      :class="$style.modal"
      @close="closeModal('tgAuth')"
      @send="login"
    />
  </layout-base-center>
</template>

<script>
import LayoutBaseCenter from '@/layouts/base-center.vue';
import TelegramAuth from '@/views/user/auth-modal.vue';

export default {
  components: {
    LayoutBaseCenter,
    TelegramAuth,
  },
  data: () => ({
    username: '',
    password: '',
    showPassword: false,
  }),
  methods: {
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    login(authCode) {
      const { username, password } = this;
      this.$store
        .dispatch('user/login', { username, password, authCode })
        .then(() => {
          if (this.$store.getters['user/telegramAuthNeeded']) this.openModal('tgAuth');
          else this.$router.push({ name: 'computers' });
        })
        .catch((reason) => {
          // eslint-disable-next-line no-alert
          alert(reason.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" module>
.login {
  background: $white;
  box-shadow: $shadow;
  padding: 20px;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.input {
  height: 30px;
  width: 300px;
  margin: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  margin: 10px;
  height: 35px;
}

.button {
  width: 115px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  color: white;
  cursor: pointer;
}

.modal {
  display: none;
  z-index: 3;
}

.eyeImg {
  margin-left: -11%;
  cursor: pointer;
}

.passwordContainer {
  display: flex;
}
</style>
