<template>
  <modal
    header-text=""
    button-text="Отправить"
    :class="$style.modal"
    @action="sendCode"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      <label :class="$style.label">
        <div>
          Введите код подтверждения, который мы отправили Вам в Telegram
        </div>
      </label>
      <input v-model="authCode" type="text" :class="$style.input">
    </div>
    <div :class="$style.newCodeContainer">
      <p>{{ currentTime }}</p>
      <button
        :disabled="currentTime > 0"
        :class="$style.button"
        @click="newCode"
      >
        Прислать новый код
      </button>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import Modal from '@/components/common/modal/login-modal.vue';

import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: { Modal },
  data: () => ({
    authCode: '',
    currentTime: 60,
    timer: null,
  }),
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer();
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--;
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },
    newCode() {
      this.currentTime = 60;
      this.startTimer();
      this.$emit('send', false);
    },
    sendCode() {
      if (this.authCode.length > 0) {
        this.$emit('send', this.authCode);
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  width: 60%;
  line-height: normal;
  margin-right: 10px;
}
.alert {
  padding: 5px;
  color: $red;
}

.newCodeContainer {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.button {
  margin: 0px 35px;
  height: 30px;
  cursor: pointer;
  width: 150px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}
</style>
