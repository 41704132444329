<template>
  <layout-base :class="$style.layoutBaseCenter">
    <slot />
  </layout-base>
</template>

<script>
import LayoutBase from '@/layouts/base.vue';

export default {
  components: {
    LayoutBase,
  },
};
</script>

<style lang="scss" module>
.layoutBaseCenter {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
</style>
